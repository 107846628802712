<template>
  <div id="app" class="app">
    <nav-bar/>
    <div class="main">
      <div class="container-fluid">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>

import NavBar from '@/components/NavBar.vue'

export default {
  components: { NavBar }
}
</script>

<style>
@import './styles.css';
#app {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.main{
  padding-top: 11vh!important;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
