import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/stray-scan',
    name: 'Stray Scan',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "StrayScan" */ '../views/StrayScan.vue')
  },
  {
    path: '/stray-dog-detail/:id',
    name: 'Stray Dog Detail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "StrayDogDetail" */ '../views/StrayDogDetail.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
