import Vue from 'vue'
import Parse from 'parse'

Parse.serverURL = 'https://parseapi.back4app.com'
Parse.initialize(
  'KHdmTgrUnzRC0nZQpYT1NWF0buXyvaOlqta5yieu',
  'F1o7govhqh7yebgftsHYqqy92Rka0cwRdY0oS3bT',
  'X7gQlFurlen4LK6sCmvmkWtbY13kUZot6tyH02AC'
)

const StrayDog = Parse.Object.extend('StrayDog')

export const run = async (filters, skip, idFilter = []) => {
  const query = new Parse.Query(StrayDog)
  for (const [key, value] of Object.entries(filters)) {
    if (value.length > 0) {
      if (isNaN(value[0]) || key === 'ID') {
        query.containedIn(key, value)
      } else {
        query.greaterThanOrEqualTo(key, value[0])
        query.lessThanOrEqualTo(key, value[1])
      }
    }
  }
  if (idFilter.length > 0) query.containedIn('ID', idFilter)
  query.skip(skip)
  query.limit(50)
  query.ascending('ID')
  const [n, data] = [await query.count(), (await query.find()).map(el => el.attributes)]
  // console.log('Query!', query, n, data)
  return [n, data]
}

export const byID = async (id) => {
  const query = new Parse.Query(StrayDog)
  query.equalTo('ID', id)
  const res = await query.find()
  if (res.length > 0) return res.map(el => el.attributes)[0]
  return null
}

const ParsePlugin = {
  install: function (VueIntance) {
    console.log('INIT Parse')
    VueIntance.prototype.$query = { run, byID }
  }
}

Vue.use(ParsePlugin)
