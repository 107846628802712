import { ethers } from 'ethers'
import {
  CONNECT,
  BALANCE,
  NETWORK,
  DISCONNECT,
  CONTRACT,
  MY_NFTS,
  STRAYDOGZ_NAMES,
  GET_STRAYDOG_NAME,
  SET_STRAYDOG_NAME
} from '../glossary'

const contractAddress = '0xea97fC2c61B8FAF98F20ba81AA8A2CF117EB04DC'

const STRAYDOGZ_ABI = [{
  inputs: [{
    internalType: 'address',
    name: 'owner',
    type: 'address'
  }],
  name: 'tokensOfOwner',
  outputs: [{
    internalType: 'uint256[]',
    name: '',
    type: 'uint256[]'
  }],
  stateMutability: 'view',
  type: 'function'
}]

const strayDogzNames = '0x7775e7936fED555CF4F081E597A95447f29Edf81'

const STRAYDOGZNAMES_ABI = [{
  inputs: [{
    internalType: 'uint256',
    name: '_tokenId',
    type: 'uint256'
  }],
  name: 'getName',
  outputs: [{
    internalType: 'bytes32',
    name: '_name',
    type: 'bytes32'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_tokenId',
    type: 'uint256'
  }, {
    internalType: 'bytes32',
    name: '_name',
    type: 'bytes32'
  }],
  name: 'setName',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}]

let provider = null
if (window.ethereum) {
  provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
} else {
  provider = ethers.getDefaultProvider()
}

export const Web3 = {
  state: {
    wallet: null,
    network: null,
    contract: new ethers.Contract(
      contractAddress,
      STRAYDOGZ_ABI,
      provider
    ),
    strayDogzNames: new ethers.Contract(
      strayDogzNames,
      STRAYDOGZNAMES_ABI,
      provider
    ),
    myNFTs: []
  },
  getters: {
    isConnected: (state) => !!state.wallet && state.network?.chainId === 1,
    myWallet: (state) => state.wallet,
    networkName: (state) => state.network?.name,
    myNFTs: (state) => state.myNFTs
  },
  actions: {
    [CONNECT]: async ({ commit, dispatch, state }) => {
      console.log('Connecting...')

      if (!provider || !provider.send) return

      if (!state.wallet) {
        await provider.send('eth_requestAccounts', [])
      } else {
        await provider.send('wallet_requestPermissions', [{ eth_accounts: {} }])
      }
      const signer = provider.getSigner()
      const address = await signer.getAddress()
      console.log(`Connected to wallet: ${address}`)
      commit(CONNECT, address)
      dispatch(MY_NFTS)
    },
    [BALANCE]: async ({ commit, state }) => {
      return await provider.getBalance(state.wallet)
    },
    [MY_NFTS]: async ({ commit, state }) => {
      commit(MY_NFTS, await state.contract.tokensOfOwner(state.wallet))
    },
    [GET_STRAYDOG_NAME]: async ({ state }, id) => {
      const bytes32Name = await state.strayDogzNames.getName(id)
      return ethers.utils.parseBytes32String(bytes32Name)
    },
    [SET_STRAYDOG_NAME]: async ({ state }, { id, name }) => {
      const signer = provider.getSigner()
      console.log('Set name', id, name)
      const tx = await state.strayDogzNames.connect(signer).setName(
        id,
        ethers.utils.formatBytes32String(name),
        {
          value: ethers.utils.parseEther('0.0025'),
          gasLimit: 100000
        })
      await tx.wait()
    }
  },
  mutations: {
    [CONNECT]: (state, wallet) => {
      state.wallet = wallet
    },
    [DISCONNECT]: (state) => {
      state.wallet = null
    },
    [NETWORK]: (state, network) => {
      state.network = network
    },
    [CONTRACT]: (state, contract) => {
      state.contract = contract
    },
    [STRAYDOGZ_NAMES]: (state, contract) => {
      state.strayDogzNames = contract
    },
    [MY_NFTS]: (state, nfts) => {
      state.myNFTs = nfts.map(nft => nft.toNumber())
    }
  }
}

export const Web3Plugin = async (store) => {
  store.commit(NETWORK, await provider.getNetwork())
  provider.on('network', (newNetwork) => {
    store.commit(NETWORK, newNetwork)
  })
}
