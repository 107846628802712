import Vue from 'vue'
import Vuex from 'vuex'

import { Web3, Web3Plugin } from './Web3'
import { StrayScan, StrayScanPlugin } from './StrayScan'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { Web3, StrayScan },
  plugins: [Web3Plugin, StrayScanPlugin]
})
