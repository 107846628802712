const glossary = [
  'CONNECT',
  'DISCONNECT',
  'BALANCE',
  'NETWORK',
  'CONTRACT',
  'MY_NFTS',
  'STRAYDOGZ_NAMES',
  'GET_STRAYDOG_NAME',
  'SET_STRAYDOG_NAME',

  'SET_PER_PAGE',
  'SET_PAGE',
  'SET_FILTER',
  'FILTER_RESET',
  'SET_TAB',
  'APPLY_FILTERS',
  'LOAD_MORE',
  'FILTER_BY_ID'
]

module.exports = Object.fromEntries(glossary.map(k => [k, k]))
