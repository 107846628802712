import {
  SET_PER_PAGE,
  SET_PAGE,
  SET_FILTER,
  FILTER_RESET,
  SET_TAB,
  APPLY_FILTERS,
  LOAD_MORE,
  FILTER_BY_ID
} from '../glossary'

import { run } from '@/plugins/straydog.api'

const DEFAULT_FILTERS = {
  Class: [],
  Rarity: [],
  Agi: [5, 65],
  Str: [5, 64],
  Def: [5, 61],
  Int: [5, 61],
  Luck: [5, 69],
  Background: [],
  Head: [],
  Mouth: [],
  Eyes: [],
  Ears: [],
  Body: [],
  Mask: [],
  Signature: []
}

export const StrayScan = {
  state: {
    tabsValue: 0,
    dogz: [],
    totalDogz: 0,
    pagination: {
      current: 1,
      perPage: 8
    },
    filters: { ...DEFAULT_FILTERS }
  },
  getters: {
    getFilterValue: (state) => val => state.filters[val],
    getAllFilters: (state) => state.filters,
    getPagination: (state) => state.pagination,
    dogz: ({ dogz }) => dogz,
    totalDogz: ({ totalDogz }) => totalDogz,
    getTabs: ({ tabsValue }) => tabsValue
  },
  actions: {
    async [SET_TAB] ({ commit, dispatch }, tabsValue) {
      commit(SET_TAB, tabsValue)
      commit(FILTER_RESET)
      dispatch(APPLY_FILTERS)
    },
    async [FILTER_BY_ID] ({ commit, dispatch }, id) {
      commit(SET_TAB, 0)
      commit(FILTER_RESET)
      dispatch(APPLY_FILTERS, [id])
    },
    async [APPLY_FILTERS] ({ state, commit, rootState }, ids = []) {
      console.log('Apply filters', ids)

      if (state.tabsValue === 1 && ids.length === 0 && rootState.Web3.myNFTs.length > 0) {
        ids = rootState.Web3.myNFTs
      }

      const [n, dogz] = await run(state.filters, 0, ids)
      commit(APPLY_FILTERS, [n, dogz])
    },
    async [LOAD_MORE] ({ state, commit, rootState }, ids = []) {
      if (state.tabsValue === 1 && ids.length === 0 && rootState.myNFTs.length > 0) {
        ids = rootState.myNFTs
      }

      const [n, dogz] = await run(state.filters, state.dogz.length, ids)
      console.log('Load more', n, dogz)
      commit(LOAD_MORE, [n, dogz])
    }
  },
  mutations: {
    [SET_PER_PAGE]: (state, perPage) => {
      state.pagination.perPage = perPage
    },
    [SET_PAGE]: (state, current) => {
      state.pagination.current = current
    },
    [SET_FILTER]: (state, { filter, value }) => {
      state.filters[filter] = value
    },
    [FILTER_RESET]: (state) => {
      state.filters = { ...DEFAULT_FILTERS }
    },
    [SET_TAB]: (state, tabsValue) => {
      state.filters = { ...DEFAULT_FILTERS }
      state.tabsValue = tabsValue
    },
    [APPLY_FILTERS]: (state, [n, dogz]) => {
      state.pagination.current = 1
      state.dogz = dogz
      state.totalDogz = n
    },
    [LOAD_MORE]: (state, [n, dogz]) => {
      state.dogz = [...state.dogz, ...dogz]
      state.totalDogz = n
    }
  }
}

export const StrayScanPlugin = (store) => {
  store.dispatch(APPLY_FILTERS)
}
