<template>
  <header :class="isSticky && 'sticky'" class="position-fixed d-flex flex-row justify-content-between align-items-center px-3 mt-0">
    <a class="logoHeader" href="/">
      <img src="@/assets/StrayDogz_Logo_white.png" alt="StrayDogz Dapp Logo">
    </a>
      <nav :class="'mainMenu'">
        <div class="toggleContainer" @click="hamburger = !hamburger">
          <div class="toggleMain" :class="{ headerOpen: hamburger }"></div>
        </div>
        <ul :class="{ headerOpen: hamburger }">
          <li>
            <div class="menu">
                <div
                :class="{ menuOpen: clicked }"
                @click="clicked = !clicked">
                  Community
                </div>
                <ul class="subMenuList" :class="{ menuOpen: clicked }">
                  <li><a :class="'d-block'" href="https://discord.gg/straydogzwtf" target="_blank">Discord</a></li>
                  <li><a :class="'d-block'" href="https://twitter.com/straydogzwtf" target="_blank">Twitter</a></li>
                  <li><a :class="'d-block'" href="https://opensea.io/collection/straydogz-official" target="_blank">Opensea</a></li>
                </ul>
            </div>
          </li>
          <li>
            <a href="https://straydogz.gitbook.io/straydogz-docs/" target="_blank">Docs</a>
          </li>
          <li>
            <router-link to="/stray-scan">Stray Scan</router-link>
          </li>
          <li class="comingSoon">
            <router-link to="#">Scavenge Run</router-link>
          </li>
        </ul>
      </nav>
      <div class="connectWallet"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
        :class="{ 'animate__animated animate__pulse': hover }"
        >
          <button v-if="isConnected" @click="disconnect" depressed>{{ shortWallet }}</button>
          <button v-else @click="connect" depressed>Connect wallet</button>
      </div>
  </header>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { CONNECT, DISCONNECT } from '@/store/glossary'

export default {
  name: 'nav-bar',
  data () {
    return {
      hover: false,
      clicked: false,
      hamburger: false,
      isSticky: false
    }
  },
  mounted () {
    this.connect()
    window.addEventListener('scroll', this.updateScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.updateScroll)
  },
  methods: {
    updateScroll () {
      this.isSticky = window.scrollY >= 50
    },
    ...mapActions({
      connect: CONNECT
    }),
    ...mapMutations({
      disconnect: DISCONNECT
    })
  },
  computed: {
    ...mapGetters([
      'isConnected',
      'networkName',
      'myWallet'
    ]),
    shortWallet () {
      return this.myWallet && `${this.myWallet.substring(0, 5)}...${this.myWallet.substring(this.myWallet.length - 5)}`
    }
  }
}
</script>

<style>
header{
    top: 0;
    right: 0;
    left: 0;
    height: 10vh;
    z-index: 100;
    transition: height .3s ease-in-out;
}
header a.logoHeader{
  position: relative;
  z-index: 1;
}
header a.logoHeader::after {
  font-family: 'Arvo', Courier, monospace;
  content: "BETA";
  color: #c97979;
  display: inline-block;
  font-weight: 700;
  transform: rotateZ(-15deg);
  position: absolute;
  background-color: white;
  line-height: 1;
  padding: 0.3em;
  margin-right: 1em;
}
header img{
  width: 90px;
}
header::before{
  content: "";
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  background-color: rgba(20,13,2);
  z-index: 0;
  transition: height .2s ease-in-out;
}
header.sticky{
  height: 7vh;
}
header.sticky:before{
  height: 7vh;
}
header.sticky img {
  width: 80px;
  margin-top: 5px;
}
.connectWallet button{
    width: 100%;
    background-color: #a8d4c5;
    font-family: 'Arvo', Courier, monospace;
    font-size: .85em;
    font-weight: 700;
    line-height: 1.2em;
    margin: 0;
    z-index: 1;
    border: 0;
    border-radius: 1em;
    padding: 1em;
    transition: padding .3s ease-in-out, font-size .3s ease-in-out;
    white-space: nowrap;
}
header.sticky .connectWallet button{
  padding: .5em;
  font-size: 1em;
}
nav.mainMenu{
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: relative;
}
.toggleContainer{
  height: 43px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.toggleMain{
  position: relative;
  visibility: visible;
  height: 100%;
  color: white;
  font-size: 2em;
  font-weight: 700;
  margin-right: .5em;
  z-index: 1;
  background-color: white;
  height: 5px;
  width: 40px;
  border-radius: 1em;
  cursor: pointer;
}
.toggleMain::before, .toggleMain::after{
  content: "";
  position: absolute;
  background-color: white;
  height: 5px;
  border-radius: 1em;
  right: 0;
  transform-origin: center right;
  transition: width .3s ease-in-out;
  cursor: pointer;
}
.toggleMain::before{
  margin-top: -10px;
  width: 30px;
}
.toggleMain::after{
  width: 50px;
  margin-top: 10px;
}
.toggleContainer:hover .toggleMain::before{
  width: 50px;
}
.toggleContainer:hover .toggleMain::after{
  width: 30px;
}
.toggleMain.headerOpen{
  width: 0;
}
.toggleMain.headerOpen::before, .toggleMain.headerOpen::after, .toggleContainer:hover .toggleMain.headerOpen::before, .toggleContainer:hover .toggleMain.headerOpen::after{
  margin: 0;
  width: 50px;
}
.toggleMain.headerOpen::before{
  transform: rotateZ(33deg) translateY(16px);
}
.toggleMain.headerOpen::after{
  transform: rotateZ(-33deg) translateY(-16px);
}
nav.mainMenu > ul{
  padding-left: 2em;
  padding-right: 2em;
  font-family: 'Arvo', Courier, monospace;
  font-size: 1.2em;
  background-color: rgba(20,13,2,.75);
  list-style: none;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  border-radius: 1.5em;
  visibility: hidden;
  height: 0;
  padding: 0;
  position: absolute;
  flex-direction: column;
  display: table;
  transition: font-size .3s ease-in-out;
}
header.sticky nav.mainMenu > ul{
  font-size: 1em;
  height: 7vh;
}
.mainMenu ul.headerOpen{
  visibility: visible;
  height: 100%;
  padding: .5em;
  margin-top: 2em;
}
.mainMenu > ul > li{
  padding: .3em;
  color: #f5eed5;
}
.mainMenu > ul > li > a{
  position: relative;
  color: #f5eed5;
  text-decoration: none;
}
.mainMenu ul li a.router-link-active, .menu > div.menuOpen{
  color: #a8d4c5;
}
.mainMenu > ul > li:hover, .mainMenu > ul > li:hover > a{
  color: #a8d4c5;
}
.menu > div{
  cursor: pointer;
}
ul.subMenuList{
  position: absolute;
  visibility: hidden;
  opacity: 0;
  height: 0;
  display: table;
  background-color: #f5eed5;
  margin-top: .5em;
  padding: .5em;
  font-size: 1.2em;
  font-weight: 700;
  font-family: 'Arvo', Courier, monospace;
  text-align: left;
  border-radius: .5em;
  transition: opacity .5s ease-in-out, visibility .1s .5s;
}
ul.subMenuList.menuOpen{
  opacity: 1;
  height: auto;
  visibility: visible;
  padding: .5em;
  transition: opacity .5s ease-in-out;
  z-index: 1;
  position: relative;
}
ul.subMenuList li{
  display: block;
}
ul.subMenuList li a{
  font-size: .85em;
  text-decoration: none;
  color: black;
  transition: color .3s ease-in-out;
  cursor: pointer;
  display: block;
}
ul.subMenuList li a:hover{
  color: #a8d4c5
}
.mainMenu ul li a[aria-expanded="false"], .mainMenu ul li a[aria-expanded="true"]{
  padding-right: .5em;
  line-height: 1em;
  display: inline-block;
}
.menu > div{
  position: relative;
  line-height: 1em;
  padding-right: .5em;
}
.menu > div::after{
  content: "▼";
  font-size: .85em;
  position: absolute;
  display:inline-block;
  top: 0;
  right: 0;
  margin-right: -.3em;
  line-height: 1em;
  transition: transform .3s ease-in-out, margin .1s ease-in-out;
  transform-origin: center;
}
.menu > div:hover::after{
  margin-top: .2em;
}
.menu > .menuOpen:hover::after{
  margin-top: -.2em;
}
.menu > .menuOpen::after{
  transform: rotateZ(180deg);
}
.mainMenu ul li.comingSoon .router-link-active{
  color: #f5eed5;
}
.mainMenu ul li.comingSoon:hover, .mainMenu ul li.comingSoon:hover a{
  color: #f5eed5;
  cursor: default;
}
.comingSoon{
  position: relative;
}
.comingSoon:before{
  content:"Coming Soon";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 0;
  font-size: .65em;
  line-height: 1em;
  color: #c97979;
}
@media (min-width:576px){
  header img{
    width: 100%;
  }
  .connectWallet button{
    font-size: 1em;
  }
}
@media (min-width:1024px){
  nav.mainMenu{
    align-items: center;
    justify-content: center;
    position: absolute;
  }
  nav.mainMenu > ul{
    visibility: visible;
    height: 8vh;
    display: flex;
    flex-direction: row;
  }
  .mainMenu > ul > li{
    padding: 1em;
  }
  .comingSoon:before{
    margin-top: .5em;
  }
  .toggleMain{
    visibility: hidden;
    height: 0;
  }
  .mainMenu ul.headerOpen{
    margin: auto;
    height: 80px;
  }
  ul.subMenuList.menuOpen{
    position: absolute;
  }
}
</style>
